<template>
  <div class="bg-[#04153D] flex h-full w-full items-center justify-center">
    <img
      class="fixed inset-0 w-full h-full object-cover object-center bg-transparent z-0"
      :src="config.authBackground as string || '/images/auth_bg.png'"
    />
    <div
      class="h-full w-full justify-center space-y-6 sm:mx-0 sm:h-auto sm:w-148 sm:space-y-10 flex bg-white flex-col rounded bg-neutral-4 p-10 shadow-outer sm:p-18 z-10"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppConfig } from "#imports";

const config = useAppConfig();
</script>
